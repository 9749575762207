<template>
	<div class="d-flex flex-column-fluid">
		<!--begin::Container-->
		<div class="container">
			<!-- begin::Card-->
			<div class="card card-custom overflow-hidden">
				<div class="card-body p-0">
					<div
						class="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0"
						style="background-image: url(/media/bg/bg-5.jpg)"
					>
						<div class="col-md-9">
							<div class="d-flex justify-content-between pb-5 pb-md-5 flex-column flex-md-row">
								<h1 class="display-4 text-white font-weight-boldest mb-5">
									<span class="symbol-label">
										<span class="svg-icon svg-icon-2x svg-icon-white">
											<inline-svg src="/media/svg/icons/Shopping/Ticket.svg" />
										</span>
									</span>
									Ticket #{{ id }}
								</h1>
								<div class="d-flex flex-column align-items-md-end px-0">
									<h1 class="display-4 text-white font-weight-boldest mb-5" v-if="loaded">
										<span class="symbol-label">
											<span class="svg-icon svg-icon-2x svg-icon-white">
												<inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
											</span>
										</span>
										Status: {{ ticket.StatusState }}
									</h1>
								</div>
							</div>
							<div v-if="loaded">
								<div class="d-flex justify-content-between pb-5 flex-column flex-md-row">
									<h2 class="display-5 text-white font-weight-bold mb-5 font-italic">
											<router-link class="text-white" :to="{ name: 'site', params: { id: ticket.SiteId } }">
												{{ ticket.SiteName }}
											</router-link>
									</h2>
								</div>
								<div class="d-flex justify-content-between pb-5 flex-column flex-md-row">
									<h3 class="display-6 text-white font-weight-bold mb-5">
										{{ ticket.Title }}
									</h3>
								</div>
								<div class="border-bottom w-100 opacity-20"></div>
								<div class="d-flex justify-content-between text-white pt-6 mb-10">
									<div class="d-flex flex-column flex-root">
										<span class="font-weight-bolder mb-2">Requester:</span>
										<span class="opacity-70">{{ ticket.Requester }} via {{ ticket['Request Mode']}}</span>
									</div>
									<div class="d-flex flex-column flex-root">
										<span class="font-weight-bolder mb-2">Created:</span>
										<span class="opacity-70">{{ ticket.created }}</span>
									</div>
									<div class="d-flex flex-column flex-root" v-if="ticket.resolved">
										<span class="font-weight-bolder mb-2">Resolved:</span>
										<span class="opacity-70">{{ ticket.resolved }}</span>
									</div>
								</div>
								<div class="d-flex justify-content-between pb-5 flex-column flex-md-row">
									<h3 class="text-white mb-5 opacity-80">
										{{ ticket.Description }}
									</h3>
								</div>
							</div>
						</div>
					</div>
					<div v-if="loaded">
						<div class="row justify-content-left py-4 px-8 py-md-10 px-md-0">
							<div class="col-md-9">
								<h1 class="pl-6">Details</h1>
							</div>
						</div>
						<div
							class="card card-custom overflow-hidden m-5"
							v-for="(d,i) in ticket.details"
							v-bind:key="i"
						>
						
							<div class="card-header">
								<span class="font-weight-bolder">{{ d.title }}</span>
								<span class="text-primary" v-if="d.IsPublic == 0"
									><br />
									(**private** - only shows for admin users)</span
								>
								<span class="opacity-70 text-primary"
									>Date: {{ d.eventDate }} by <i>{{ d.Sender }}</i></span
								>
							</div>

							<div class="card-body p-0 m-10">
								<span v-html="sanitize(d.description)"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end::Card-->
		</div>
		<!--end::Container-->
	</div>
	<!--end::Entry-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import sanitizeHTML from 'sanitize-html';

export default {
	name: 'TicketDetails',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			loaded: false,
			ticket: null,
		};
	},
	components: { },
	methods: {
		sanitize(dirty) {
			return sanitizeHTML(dirty, {
				allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img']),
			});
		},
	},
	mounted() {
		this.loaded = false;
		this.$http.get(`ticket/${this.id}`).then((resp) => {
			this.ticket = resp.data.data;
			this.loaded = true;
		});
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Ticket #' + this.id }]);
	},
};
</script>

<style>
</style>